<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
    <v-card v-if="dialog">
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">COTIZACION/POLIZA</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-expansion-panels v-model="panel" dense v-if="img != null">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Producto imagen
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-img :aspect-ratio="16 / 9" :src="img" contain />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-stepper v-model="stepper" flat>
          <v-stepper-header class="d-flex justify-space-around">
            <v-stepper-step step="1">
              Datos poliza
            </v-stepper-step>

            <v-stepper-step step="2">
              Datos cliente
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- 1ST ITEM (FORM) -->
            <v-stepper-content step="1">
              <v-form ref="generales">
                <v-row dense>
                  <v-col cols="4" v-if="invisible">
                    <v-text-field
                      label="No. Póliza"
                      v-model="form.poliza"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-text-field
                      label="Poliza ID Interna"
                      v-model="form.id"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-select
                      label="Tipo de Póliza"
                      v-model="form.type"
                      :items="typePolizaList"
                      placeholder="Requerido"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="4">
                                        <v-checkbox
                                            label="Obtener certificado"
                                            v-model="enviarPorCorreo"
                                        ></v-checkbox>
                                    </v-col> -->
                  <v-col cols="4">
                    <v-select
                      label="Estatus de la Póliza"
                      v-model="form.status"
                      :items="statusList"
                      item-text="name"
                      item-value="id"
                      :disabled="roles != 'ADMIN'"
                      readonly
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="productoAseguradora == 'Vida-Ahorro'"
                    cols="12"
                    class="mb-3"
                  >
                    <declaracionSalud
                      :accepted="declaracionSaludAccepted"
                      @change="declaracionSaludAccepted = $event"
                    ></declaracionSalud>
                  </v-col>

                  <!-- FUNCIONALIDAD DE LISTA PENDIENTE -->
                  <v-col cols="4" v-if="invisible">
                    <v-autocomplete
                      label="Póliza renovada por"
                      v-model="form.anterior"
                      readonly
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-text-field
                      label="No. Cotización / Lead ID"
                      v-model="form.leadId"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <!-- INICIO DE CHECKS -->
                  <v-col cols="4">
                    <v-text-field
                      label="Cliente"
                      v-model="form.newCliente"
                      readonly
                      :disabled="canEdit"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Tipo de contratante"
                      v-model="form.tipo_contratante"
                      :items="tipos_contratante"
                      v-if="productoAseguradora == 'Hogar'"
                      :disabled="canEdit"
                      item-text="text"
                      item-value="value"
                      :rules="[
                        ...(productoAseguradora == 'Hogar'
                          ? [$rules.required]
                          : []),
                      ]"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-autocomplete
                      label="Agente"
                      :items="agentes"
                      item-text="nombre"
                      :disabled="canEdit"
                      item-value="id"
                      v-model="form.agente"
                      placeholder="Requerido"
                      :rules="[$rules.required]"
                      @change="esDirecto()"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="4" v-if="esAgentDirecto">
                    <v-autocomplete
                      label="Comercial Asignado"
                      :items="comercialAsignadoList"
                      :disabled="canEdit"
                      item-text="nombre"
                      item-value="id"
                      v-model="form.comercialAsignado"
                      placeholder="Requerido"
                      :rules="[$rules.required]"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      label="Plan"
                      v-model="form.productos"
                      :items="productos"
                      :disabled="
                        canEdit ||
                          (productoAseguradora == 'Hogar' &&
                            !form.tipo_contratante) ||
                          (form.id != null && form.status == 0)
                      "
                      item-text="nombre"
                      item-value="id"
                      placeholder="Requerido"
                      :rules="[$rules.required]"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Suma asegurada"
                      v-model="form.suma_asegurada"
                      v-if="productoAseguradora == 'Hogar'"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" v-if="form.id != null && form.status == 0">
                    <v-checkbox
                      label="Obtener certificado"
                      v-model="form.checkgetCertificado"
                      :disabled="canViewEmitir"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-alert
                      dense
                      text
                      type="error"
                      v-if="form.id != null && canViewEmitir"
                    >
                      <!-- {{porcentaje}} -->
                      Por favor, debes crear uno o hasta 5 beneficiarios que
                      cubran el 100% de porcentaje de asignación para que se
                      pueda asignar un certificado
                    </v-alert>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="menuInicioVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormattedInicioVigencia"
                          :disabled="canEdit"
                          label="Fecha inicio de vigencia"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="
                            form.comboInicioVigencia = parseDate(
                              dateFormattedInicioVigencia
                            )
                          "
                          v-on="on"
                          v-mask="'##/##/####'"
                          :rules="[$rules.required]"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        @input="menuInicioVigencia = false"
                        v-model="form.comboInicioVigencia"
                        no-title
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="4">
                    <v-menu
                      ref="menuFinVigencia"
                      v-model="menuFinVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormattedFinVigencia"
                          :disabled="disable"
                          label="Fecha fin de vigencia"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="
                            form.comboFinVigencia = parseDate(
                              dateFormattedFinVigencia
                            )
                          "
                          v-on="on"
                          v-mask="'##/##/####'"
                          :rules="[$rules.required]"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.comboFinVigencia"
                        no-title
                        @input="menuFinVigencia = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-select
                      label="Periodicidad de pago"
                      v-model="form.periodicidad"
                      :items="periodicidadList"
                      item-text="name"
                      :disabled="disable"
                      item-value="id"
                      :rules="[$rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-text-field
                      label="Años"
                      v-model="form.years"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="invisible">
                    <v-text-field
                      label="Número de cuotas"
                      v-model="form.numeroCuotas"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Prima neta"
                      v-model="form.primaNeta"
                      type="number"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Prima total"
                      v-model="form.primaTotal"
                      type="number"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" v-if="invisible">
                    <v-select
                      label="Forma de Pago"
                      v-model="form.formaPago"
                      :items="formaPagoList"
                      item-text="name"
                      item-value="id"
                      :rules="[$rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Monto de Primer Pago"
                      v-model="form.montoPrimerPago"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.periodicidad == 1 && form.ramo != 'AHORRO'"
                  >
                    <v-text-field
                      label="Monto de Pago Subsecuente"
                      v-model="form.montoSubsecuente"
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormattedPrimerPago"
                          label="Fecha primer pago"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          :disabled="disable"
                          @blur="
                            form.comboPrimerPagoDate = parseDate(
                              dateFormattedPrimerPago
                            )
                          "
                          v-on="on"
                          v-mask="'##/##/####'"
                          :rules="[$rules.required]"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.comboPrimerPagoDate"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Notas de la Póliza"
                      v-model="form.notas"
                      :disabled="canEdit"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="invisible">
                    <v-text-field
                      v-model="form.creadoPorDate"
                      label="Fecha de Creación"
                      prepend-icon="mdi-calendar"
                      v-mask="'##/##/####'"
                      placeholder="Requerido"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="invisible">
                    <v-text-field label="Creado por" v-model="form.creadoPor">
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="invisible">
                    <v-text-field
                      v-model="form.ultimaModoficacionDate"
                      label="Última Modificación"
                      prepend-icon="mdi-calendar"
                      v-mask="'##/##/####'"
                      placeholder="Requerido"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="invisible">
                    <v-text-field
                      label="Modificado por"
                      v-model="form.ultimaModoficacion"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <!-- ENDS HERE -->
              <v-btn
                color="primary"
                @click="crearPoliza()"
                :disabled="canEdit || IsIssuing"
                :loading="IsIssuing"
              >
                Guardar
              </v-btn>
              <v-btn
                color="primary"
                style="margin-left: 15px"
                @click="nextForm"
              >
                Siguiente
              </v-btn>
              <v-btn
                color="primary"
                style="margin-left: 15px"
                @click="crearBeneficiario"
                v-if="
                  form.id != null &&
                    form.ramo == 'VIDA' &&
                    productoAseguradora != 'Hospitalizacion'
                "
                :disabled="canEdit && !canCreateBeneficarioVA"
              >
                Crear Beneficiario
              </v-btn>
            </v-stepper-content>

            <!-- 2ND ITEM (2ND FORM) -->
            <v-stepper-content step="2">
              <v-form ref="polizas">
                <v-row
                  style="padding: 20px; margin-top: -35px; margin-bottom: 5%;"
                >
                  <v-col cols="4">
                    <v-select
                      label="Tipo de cliente"
                      v-model="form.tipo_cliente"
                      :disabled="
                        canEdit || productoAseguradora == 'Vida-Ahorro'
                      "
                      :items="tipos_cliente"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Tipo de Persona (Régimen)"
                      v-model="form.regimen"
                      :items="regimentList"
                      :disabled="disable"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen == 'Moral' && form.regimen != null"
                  >
                    <v-text-field
                      label="Razón Social"
                      v-model="form.razonSocial"
                      :rules="[$rules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-text-field
                      label="Nombre(s)"
                      v-model="form.nombres"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-text-field
                      label="Apellido Paterno"
                      v-model="form.apellidoPaterno"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-text-field
                      label="Apellido Materno"
                      :rules="[$rules.required]"
                      :disabled="canEdit"
                      v-model="form.apellidoMaterno"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-select
                      label="Estado Civil"
                      :disabled="canEdit"
                      :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
                      v-model="form.statusCivil"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-menu
                      ref="menuFechaNacimiento"
                      v-model="menuFechaNacimiento"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormattedFechaNacimiento"
                          :disabled="canEdit"
                          label="Fecha de Nacimiento"
                          persistent-hint
                          :rules="[$rules.required]"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="
                            form.comboFechaNacimiento = parseDate(
                              dateFormattedFechaNacimiento
                            )
                          "
                          v-on="on"
                          v-mask="'##/##/####'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.comboFechaNacimiento"
                        no-title
                        @input="menuFechaNacimiento = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="form.edad"
                      label="EDAD"
                      placeholder="Requerido"
                      :disabled="disable"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="form.rfc"
                      label="RFC"
                      placeholder="Requerido"
                      :disabled="canEdit"
                      :rules="rfcRules"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col v-if="productoAseguradora == 'Vida-Ahorro'" cols="12" class="pa-0">
                    <FormCurp
                      :formData="{
                        ...form,
                        fechaNacimiento: dateFormattedFechaNacimiento,
                      }"
                      @change="curpFormValid = $event"
                      @formChange="curpFormData = $event"
                    ></FormCurp>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="form.regimen != 'Moral' && form.regimen != null"
                  >
                    <v-select
                      label="Género"
                      v-model="form.selectedGenero"
                      :items="generoList"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Teléfono"
                      v-model="form.celular"
                      v-mask="'##########'"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      v-model="form.correoElectronico"
                      label="Correo Electrónico"
                      :items="prediccionesCorreo"
                      :filter="filtroCorreo"
                      :disabled="canEdit"
                      :rules="emailRules"
                      :append-icon="null"
                    ></v-autocomplete>
                  </v-col>
                  <v-col v-if="form.regimen == 'Física'" cols="4">
                    <v-select
                      v-model="form.facturar"
                      label="¿Desea facturar?"
                      :items="facturacionItems"
                      :disabled="
                        canEdit || productoAseguradora == 'Vida-Ahorro'
                      "
                      :hint="
                        productoAseguradora == 'Vida-Ahorro'
                          ? 'El producto no admite facturación'
                          : ''
                      "
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="form.facturar && form.regimen == 'Física'"
                    cols="4"
                  >
                    <v-select
                      v-model="form['regimen-fiscal']"
                      label="Tipo de régimen"
                      :items="tipoRegimenItems"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="form.facturar && form.regimen == 'Física'"
                    cols="4"
                  >
                    <v-select
                      v-model="form.cfdi"
                      label="Concepto CFDI"
                      :items="conceptoCFDIItems"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Calle"
                      v-model="form.calle"
                      :rules="[$rules.required]"
                      :disabled="canEdit"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="No. Exterior"
                      v-model="form.num_exterior"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="No. Interior"
                      v-model="form.num_interior"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Colonia"
                      :disabled="canEdit"
                      :items="colonias"
                      v-model="form.colonia"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Código Postal"
                      v-model="form.codigoPostal"
                      v-mask="'#####'"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" v-if="form.regimen != null">
                    <v-select
                      label="Estado de la República"
                      v-model="form.estadoRepublicaSelected"
                      :disabled="canEdit"
                      :items="estados"
                      item-text="name"
                      item-value="id"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Municipio"
                      v-model="form.municipio"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" style="padding-buttom:5%">
                    <v-textarea
                      label="Dirección"
                      :rules="[$rules.required]"
                      :disabled="canEdit"
                      v-model="form.direccion"
                      rows="1"
                      auto-grow
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                    <v-text-field
                      label="Año de construcción (Ejemplo: 2012)"
                      v-model="form.años_construccion"
                      type="number"
                      v-mask="'####'"
                      :disabled="canEdit"
                      :rules="[
                        () =>
                          !!form.años_construccion || 'El campo es requerido',
                        () =>
                          (!!form.años_construccion &&
                            form.años_construccion.length == 4) ||
                          'El campo debe de tener 4 digitos',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                    <v-select
                      label="Tipo de construcción"
                      :disabled="canEdit"
                      :items="['Maciza']"
                      v-model="form.tipo_construccion"
                      :rules="[$rules.required]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                    <v-text-field
                      label="No. de Pisos"
                      v-model="form.num_pisos"
                      type="number"
                      :disabled="canEdit"
                      v-mask="'##'"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                    <v-text-field
                      label="Piso en el que vive"
                      v-model="form.piso_vive"
                      type="number"
                      :disabled="canEdit"
                      v-mask="'##'"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Celular'">
                    <v-text-field
                      label="Marca y Modelo Celular"
                      v-model="form.modeloMarcaCelular"
                      :disabled="canEdit"
                      :rules="[$rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="productoAseguradora == 'Celular'">
                    <v-text-field
                      label="Precio Factura Celular"
                      v-model="form.precioFacturaCelular"
                      :rules="[$rules.required, $rules.numeric]"
                      :disabled="canEdit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-btn
                color="primary"
                @click="crearPoliza()"
                :disabled="canEdit || IsIssuing"
                :loading="IsIssuing"
              >
                Guardar
              </v-btn>

              <v-btn text @click="stepper = 1"> Regresar </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <BeneficiariosDialog
      v-if="beneficiarioDialog"
      :open.sync="beneficiarioDialog"
      :porcentajeTotal="porcentaje"
      :rfcTitular="form.rfc"
      :emision="currentEmisionId"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ListPoliza } from "@/plugins/MixinsPoliza.js";
import BeneficiariosDialog from "./BeneficiariosDialog.vue";
import declaracionSalud from "./declaracionSalud.vue";
import RfcFacil from "rfc-facil";
import {
  dominiosComunes,
  validarDominioComun,
} from "./ValidacionDominiosComunes";
import FormCurp from "./FormCurp.vue";

var moment = require("moment-timezone");
export default {
  name: "leads.detalles.FormPolizas",
  mixins: [ListPoliza],
  components: {
    BeneficiariosDialog,
    declaracionSalud,
    FormCurp,
  },
  props: {
    identificador: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: String,
      required: true,
    },
    productoAseguradora: {
      type: String,
      required: true,
    },
    poliza: {
      default: null,
    },
    isEmbbeded: {
      default: 1,
    },
    global_id: {
      type: String,
      default: "0000",
    },
    corte: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      img: null,
      panel: 2,
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
        validarDominioComun,
      ],
      rfcRules: [
        (v) => !!v || "RFC es necesario",
        (v) =>
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2}|)([A\d]|)$/.test(
            v
          ) || "RFC no es valido, recordar escribir en mayúsculas",
      ],
      tipos_cliente: [
        { text: "Titular", value: "titular" },
        { text: "Asegurado", value: "asegurado" },
      ],
      porcentajeBeneficiarios: null,
      send: false,
      dialog: false,
      disable: true,
      stepper: 1,
      currentEmisionId: null,
      beneficiarioDialog: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      menuFechaNacimiento: null,
      menuInicioVigencia: false,
      menuFinVigencia: false,
      esAgentDirecto: false,
      menu1: false,
      dateFormattedInicioVigencia: moment().format("DD-MM-YYYY"),
      dateFormattedFinVigencia: moment()
        .add(1, "y")
        .format("DD-MM-YYYY"),
      dateFormattedPrimerPago: moment().format("DD-MM-YYYY"),
      dateFormattedFechaNacimiento: null,
      copia_productos_originales: [],
      currentRamo: null,
      form: {
        tipo_cliente: null,
        poliza: null,
        id: null,
        status: 0,
        type: "Nueva",
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: 707,
        landing: null,
        leadId: null,
        aseguradoraId: null,
        anterior: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: 4,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: 1,
        numeroCuotas: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        statusCivil: null,
        direccion: null,
        regimen: "Física",
        razonSocial: null,
        notas: null,
        rfc: null,
        canceladaFinalizadaMotivo: null,
        checkRenovada: null,
        checkGenerarCuotas: null,
        checkReactivarPoliza: null,

        // new
        comercialAsignado: null,
        years: 0,
        ramo: null,
        comboInicioVigencia: null,
        comboFinVigencia: null,
        comboPrimerPagoDate: null,
        comboFechaNacimiento: null,
        edad: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        municipio: null,
        checkgetCertificado: false,
        suma_asegurada: null,
        tipo_contratante: null,
        años_construccion: null,
        tipo_construccion: null,
        num_pisos: null,
        piso_vive: null,
        polizaAseguradora: null,
        facturar: false,
      },
      ramoAuto: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        vehiculo: null,
        detallePoliza: null,
        plan: null,
        origenAuto: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
      },
      ramoDanio: {
        tipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
      ramoSalud: {
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        fechasParaCotizar: null,
        dependientes: null,
      },
      ramoVida: {
        planVida: null,
        fumadorVida: null,
        sumaAseguradaVida: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
      ramoAhorro: {
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },
      ramoFinanzas: {
        contratoAdjunto: null,
      },
      invisible: false,
      usuario_detalle: {},
      tipos_contratante: [
        { text: "Propietario", value: "propietario" },
        { text: "Inquilino", value: "inquilino" },
      ],
      canViewEmitir: true,
      colonias: [],
      actualStatus: "",
      busquedaCorreo: "",
      operadores: [],
      etiqueta_aseguradora: null,
      comercialAsignadoList: [],
      IsIssuing: false,
      facturacionItems: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      tipoRegimenItems: [
        "Sueldos y Salarios e Ingresos Asimilados a Salarios",
        "Arrendamiento",
        "Régimen de Enajenación o Adquisición de Bienes",
        "Demás ingresos",
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
        "Ingresos por Dividendos (socios y accionistas)",
        "Personas Físicas con Actividades Empresariales y Profesionales",
        "Ingresos por intereses",
        "Régimen de los ingresos por obtención de premios",
        "Sin obligaciones fiscales",
        "Incorporación Fiscal",
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        "Régimen Simplificado de Confía",
        "",
      ],
      conceptoCFDIItems: [
        "Gastos en general",
        "Primas por seguros de gastos médicos",
        "Sin efectos fiscales",
      ],

      catalogoCelular: [],
      declaracionSaludAccepted: false,
      curpFormValid: false,
      curpFormData: {
        entidad: "",
        curp: "",
      },
    };
  },
  computed: {
    ...mapState("polizas", ["porcentaje", "beneficiarios"]),
    canEdit() {
      var canView = true;
      var roles = [];
      var isAgent = false;
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "ADMINCC",
        "MANAGERCC",
        "AGENTCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = false;
        }
      });
      roles.forEach((element) => {
        if ("AGENTCC" == element) {
          isAgent = true;
        }
      });
      if (this.form.status != 0 && isAgent) {
        canView = true;
      }

      return canView;
    },

    canCreateBeneficarioVA() {
      const rolesAdmitedToCreate = [
        "ADMIN",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "ADMINCC",
        "MANAGERCC",
        "AGENTCC",
      ];
      const principalRole = this.roles[0].rol;
      const isAdmited = rolesAdmitedToCreate.some(
        (role) => role == principalRole
      );

      return this.form.status != 0 && isAdmited;
    },

    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },

    operadorId() {
      if (this.currentRol == "OPERADOR" || this.currentRol == "OPERACIONES") {
        if (this.operadores.length > 0) {
          var currentOperador = this.operadores.find(
            (e) => e.uuid == localStorage.agenteUuid
          );
          return currentOperador != -1 ? currentOperador.id : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    currentRol() {
      let roles = [];
      let CurrentRoles = JSON.parse(localStorage.getItem("roles"));
      CurrentRoles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
  watch: {
    "form.tipo_cliente"(val) {
      if (this.poliza == null) {
        if (val == "titular") {
          this.form.nombres = this.usuario_detalle.nombre;
          this.form.apellidoPaterno = this.usuario_detalle.apellido_Paterno;
          this.form.apellidoMaterno = this.usuario_detalle.apellido_Materno;
          this.form.celular = this.usuario_detalle.celular;
          this.form.selectedGenero = this.usuario_detalle.sexo;
          this.form.correoElectronico = this.usuario_detalle.correo;
          this.form.direccion = this.usuario_detalle.direccion;
          this.form.codigoPostal = this.usuario_detalle.codigoPostal;
          this.form.rfc = this.usuario_detalle.rfc;
          var año = moment(this.usuario_detalle.fecha_nacimiento).format(
            "YYYY"
          );
          var dia = moment(this.usuario_detalle.fecha_nacimiento).format("DD");
          var mes = moment(this.usuario_detalle.fecha_nacimiento).format("MM");
          this.dateFormattedFechaNacimiento = dia + "/" + mes + "/" + año;
        } else {
          this.form.nombres = "";
          this.form.apellidoPaterno = "";
          this.form.apellidoMaterno = "";
          this.form.celular = "";
          this.form.selectedGenero = "";
          this.form.correoElectronico = "";
          this.form.direccion = "";
          this.form.codigoPostal = "";
          (this.form.rfc = ""), (this.dateFormattedFechaNacimiento = "");
        }
      }
    },
    "form.nombres"() {
      this.actualizarRfc();
    },
    "form.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "form.apellidoMaterno"() {
      this.actualizarRfc();
    },
    "form.codigoPostal"(val) {
      if (this.productoAseguradora == "Hogar") {
        this.calcularDireccionAig(val);
        return;
      }
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("v1/catalogo/direccion?cp=" + val, config)
        .then((response) => {
          this.colonias = [];
          response.data.listaColonias.forEach((item) => {
            this.colonias.push(item.nombreColonia);
          });
          this.form.municipio = response.data.nombreMunicipio;
          this.form.estadoRepublicaSelected = Number(response.data.nEstado);
        });
    },
    dialog(val) {
      this.$emit("update:active", val);
    },
    dateFormattedFechaNacimiento(newValue) {
      var years = moment(newValue, "DD/MM/YYYY");
      var today = moment().format("DD/MM/YYYY");
      var diference = moment(today, "DD/MM/YYYY").diff(
        moment(years, "DD/MM/YYYY"),
        "years"
      );
      this.form.edad = diference;
    },
    dateFormattedInicioVigencia() {
      var date = moment(this.dateFormattedInicioVigencia, "DD-MM-YYYY");
      date = date.add(1, "y");
      this.dateFormattedFinVigencia = date.format("DD-MM-YYYY");
    },
    "form.comboInicioVigencia"(val) {
      this.dateFormattedInicioVigencia = this.formatDate(val);
    },
    "form.comboFinVigencia"(val) {
      this.dateFormattedFinVigencia = this.formatDate(val);
    },
    "form.comboPrimerPagoDate"(val) {
      this.dateFormattedPrimerPago = this.formatDate(val);
    },
    "form.comboFechaNacimiento"(val) {
      this.dateFormattedFechaNacimiento = this.formatDate(val);
      this.actualizarRfc();
    },
    "form.years": function(value) {
      if (value.typeof != "number") {
        const meses = value * 12;
        switch (this.form.periodicidad) {
          case 1:
            this.form.numeroCuotas = meses / 12;
            break;
          case 2:
            this.form.numeroCuotas = meses / 6;
            break;
          case 3:
            this.form.numeroCuotas = meses / 3;
            break;
          case 4:
            this.form.numeroCuotas = meses / 1;
            break;
          default:
            break;
        }
      }
    },
    "form.productos": function(value) {
      if (this.poliza == null) {
        if (value != null) {
          this.obtenerRamo(value);
          this.obtenerAseguradoraId(value);
          this.ObtenerDatosPagos(value);
          this.ObtenerSumaAsegurada(value);
        } else {
          this.form.ramo = null;
          this.form.suma_asegurada = null;

          this.form.primaNeta = null;
          this.form.primaTotal = null;
          this.form.montoPrimerPago = null;
          this.form.montoSubsecuente = null;
          this.form.aseguradoraId = null;
        }
      }
    },
    "form.tipo_contratante": function(value) {
      this.productos = [];
      this.form.productos = null;

      if (this.poliza == null) {
        this.form.productos = null;
      }

      var nombre = "";
      var result = null;
      const Inquilino = "Inquilino";

      this.copia_productos_originales.forEach((element) => {
        nombre = element.nombre;
        result = nombre.includes(Inquilino);
        if (value == "inquilino" && result) {
          this.productos.push({
            nombre: element.nombre,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId ? element.aseguradoraId : null,
            aseguradora_id: element.aseguradora_id
              ? element.aseguradora_id
              : null,
            primaNeta: element.primaNeta,
            primaTotal: element.primaTotal,
            primerPago: element.primerPago,
            Subsecuente: element.Subsecuente,
            url_imagen: element.url_imagen,
            suma_asegurada: element.suma_asegurada,
            etiqueta_aseguradora: element.etiqueta_aseguradora
              ? element.etiqueta_aseguradora
              : null,
          });
          if (this.productos.length) this.form.productos = this.productos[0].id;
        } else if (value == "propietario" && !result) {
          this.productos.push({
            nombre: element.nombre,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId ? element.aseguradoraId : null,
            aseguradora_id: element.aseguradora_id
              ? element.aseguradora_id
              : null,
            primaNeta: element.primaNeta,
            primaTotal: element.primaTotal,
            primerPago: element.primerPago,
            Subsecuente: element.Subsecuente,
            url_imagen: element.url_imagen,
            suma_asegurada: element.suma_asegurada,
            etiqueta_aseguradora: element.etiqueta_aseguradora
              ? element.etiqueta_aseguradora
              : null,
          });
          if (this.productos.length) this.form.productos = this.productos[0].id;
        }
      });
    },
    "form.ramo": function(value) {
      if (value == "DAÑOS" && this.productoAseguradora == "Hogar") {
        this.form.formaPago = 4;
      }
    },
    beneficiarios: function(val) {
      if (this.porcentaje >= 100 || val.length >= 5) {
        this.canViewEmitir = false;
      }
      // if(this.productoAseguradora == 'Hogar'){
      //     this.canViewEmitir = false;
      // }
    },
    "form.correoElectronico"(v) {
      this.busquedaCorreo = v;
    },
  },
  async mounted() {
    console.log(this.productoAseguradora);

    this.obtenerAgentes();
    this.obtenerProductos();
    this.obtenerUsersCanalDirecto();
    this.LoadDetalleLead();

    if (this.poliza != null) {
      await this.loadPolizaData(); //await load poliza
      if (
        ["Hogar", "Celular", "Hospitalizacion", "Asistencias"].find(
          (e) => e == this.productoAseguradora
        )
      ) {
        this.canViewEmitir = false;
      } else {
        await this.getBeneficiarios(this.poliza.id);
      }
    }

    this.dialog = this.active;
    this.form.years = 1;
    var day = moment().format("DD");
    if (day > this.corte) {
      var año = moment().format("YYYY");
      var mes = moment().format("MM");
      var date = this.corte + "/" + mes + "/" + año;
      var date_formatt = moment(date, "DD/MM/YYYY");
      var newMoment = date_formatt.add(1, "M").format("DD/MM/YYYY");
      this.dateFormattedPrimerPago = newMoment;
      console.log("arriba: ", newMoment);
    } else {
      var año_actual = moment().format("YYYY");
      var mes_actual = moment().format("MM");
      var date2 = this.corte + "/" + mes_actual + "/" + año_actual;
      var date_actual2 = moment(date2, "DD/MM/YYYY");
      var newMoment2 = date_actual2.format("DD/MM/YYYY");
      this.dateFormattedPrimerPago = newMoment2;
    }
    if (this.productoAseguradora == "Hogar") {
      this.form.tipo_construccion = "Maciza";
    }
    this.loadOperadores();
  },
  methods: {
    ...mapActions("polizas", [
      "getBeneficiarios",
      "getPolizas",
      "getNuevasPolizas",
    ]),
    ...mapActions("leads", ["LoadDetalleLead"]),

    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },

    async loadPolizaData() {
      try {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        await this.$axios
          .get(`v1/emision/id/${this.poliza.id}`, config)
          .then((response) => {
            var data = response.data;
            let jsonFormat = JSON.parse(data.requestJson);
            this.form.id = data.id ? data.id : null;
            this.comercialAsignado = data.comercial_asignado
              ? data.comercial_asignado
              : null;
            this.form.formaPago = jsonFormat.forma_pago
              ? jsonFormat.forma_pago
              : null;
            this.form.creadoPorDate = data.fecha
              ? moment(data.fecha, "YYYY-MM-DD").format("DD/MM/YYYY")
              : null;
            this.form.poliza = data.poliza ? data.poliza : null;
            this.form.primaNeta = jsonFormat.prima_neta
              ? jsonFormat.prima_neta
              : null;
            this.form.primaTotal = jsonFormat.prima_total
              ? jsonFormat.prima_total
              : null;
            this.form.montoPrimerPago = jsonFormat.primer_pago
              ? jsonFormat.primer_pago
              : null;
            this.form.montoSubsecuente = jsonFormat.subsecuente_pago
              ? jsonFormat.subsecuente_pago
              : null;
            this.form.periodicidad = jsonFormat.pago_id
              ? jsonFormat.pago_id
              : null;
            this.form.aseguradoraId = jsonFormat.aseguradora_id
              ? jsonFormat.aseguradora_id
              : null;
            this.form.agente = jsonFormat.agente ? jsonFormat.agente : null;
            this.form.estadoRepublicaSelected = jsonFormat.estado_id
              ? jsonFormat.estado_id
              : null;
            this.form.dateFormattedInicioVigencia = data.inicio
              ? moment(data.inicio, "YYYY-MM-DD").format("DD/MM/YYYY")
              : null;
            this.form.years = jsonFormat.anios ? jsonFormat.anios : null;
            this.form.dateFormattedFinVigencia = data.fin
              ? moment(data.fin, "YYYY-MM-DD").format("DD/MM/YYYY")
              : null;
            this.form.status = data.status ? data.status : 0;
            this.form.celular = jsonFormat.telefono
              ? jsonFormat.telefono
              : null;
            this.form.correoElectronico = jsonFormat.correo_electronico
              ? jsonFormat.correo_electronico
              : null;
            this.form.correoElectronico = jsonFormat.correo_electronico
              ? jsonFormat.correo_electronico
              : null;
            this.form.productos = data.productoId ? data.productoId : null;
            this.form.nombres = data.nombre
              ? data.nombre
              : jsonFormat.nombre
              ? jsonFormat.nombre
              : null;
            this.form.apellidoPaterno = data.apellidoPaterno
              ? data.apellidoPaterno
              : null;
            this.form.apellidoMaterno = data.apellidoMaterno
              ? data.apellidoMaterno
              : null;
            this.form.codigoPostal = jsonFormat.codigo_postal
              ? jsonFormat.codigo_postal
              : null;
            this.form.regimen = jsonFormat.tipo_persona
              ? jsonFormat.tipo_persona
              : null;
            this.form.razonSocial = jsonFormat.razon_social
              ? jsonFormat.razon_social
              : null;
            this.form.statusCivil = jsonFormat.estado_civil
              ? jsonFormat.estado_civil
              : null;
            this.form.direccion = jsonFormat.direccion
              ? jsonFormat.direccion
              : null;
            this.form.notas = jsonFormat.notas ? jsonFormat.notas : null;
            this.form.type = jsonFormat.tipo_poliza
              ? jsonFormat.tipo_poliza
              : null;
            this.form.dateFormattedPrimerPago = data.fechaPrimerPago
              ? moment(data.fechaPrimerPago).format("YYYY-MM-DD")
              : null;
            this.dateFormattedFechaNacimiento = jsonFormat.fecha_nacimiento
              ? moment(jsonFormat.fecha_nacimiento, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : null;
            this.form.selectedGenero = jsonFormat.genero
              ? jsonFormat.genero
              : null;
            this.form.rfc = jsonFormat.rfc ? jsonFormat.rfc : null;
            this.identificador = data.lead_id ? data.lead_id : null;
            this.form.calle = jsonFormat.calle ? jsonFormat.calle : null;
            this.form.num_exterior = jsonFormat.num_exterior
              ? jsonFormat.num_exterior
              : null;
            this.form.num_interior = jsonFormat.num_interior
              ? jsonFormat.num_interior
              : null;
            this.form.colonia = jsonFormat.colonia ? jsonFormat.colonia : null;
            this.form.municipio = jsonFormat.municipio
              ? jsonFormat.municipio
              : null;
            this.form.id = data.id ? data.id : null;
            this.form.uuid = data.uuid ? data.uuid : null;
            this.form.tipo_contratante = jsonFormat.tipo_contratante
              ? jsonFormat.tipo_contratante
              : null;
            this.form.checkgetCertificado = jsonFormat.checkgetCertificado
              ? jsonFormat.checkgetCertificado
              : null;
            this.form.tipo_cliente = jsonFormat.tipo_cliente
              ? jsonFormat.tipo_cliente
              : null;
            this.form.suma_asegurada = jsonFormat.suma_asegurada
              ? jsonFormat.suma_asegurada
              : null;
            this.form.años_construccion = jsonFormat.años_construccion
              ? jsonFormat.años_construccion
              : null;
            this.form.tipo_construccion = jsonFormat.tipo_construccion
              ? jsonFormat.tipo_construccion
              : null;
            this.form.num_pisos = jsonFormat.num_pisos
              ? jsonFormat.num_pisos
              : null;
            this.form.piso_vive = jsonFormat.piso_vive
              ? jsonFormat.piso_vive
              : null;
            (this.form.edad = jsonFormat.edad ? jsonFormat.edad : null),
              (this.form.ramo = jsonFormat.ramo ? jsonFormat.ramo : null),
              (this.form.polizaAseguradora = data.polizaAseguradora
                ? data.polizaAseguradora
                : null);

            this.form.modeloMarcaCelular = jsonFormat.modeloMarcaCelular;
            this.form.precioFacturaCelular = jsonFormat.precioFacturaCelular;
            this.declaracionSaludAccepted =
              jsonFormat.declaracionSaludAccepted || false;
            this.form.entidad = jsonFormat.entidad;
            this.form.curp = jsonFormat.curp;
          });
        // this.loadBeneficiarios()
      } catch (exception) {
        console.error(exception);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    esDirecto() {
      this.agentes.map((element) => {
        let elemento = JSON.parse(JSON.stringify(element));
        let canalList = elemento.canalList;
        if (element.id == this.form.agente) {
          canalList.map((elm) => {
            if (elm.id == 3) {
              this.esAgentDirecto = true;
            } else {
              this.esAgentDirecto = false;
            }
          });
        }
      });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      // /v1/agente/operadores/1
      this.$axios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
          });
        });
      });
    },
    crearBeneficiario() {
      this.currentEmisionId = this.form.id;
      this.beneficiarioDialog = true;
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          response.data.map((element) => {
            this.comercialAsignadoList.push({
              nombre: element.nombre,
              id: element.id,
              uuid: element.uuid,
            });
          });
        });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .post(
          "/v1/producto/leadbradescard",
          {
            producto_lead: this.producto,
            producto_aseguradora: this.productoAseguradora,
          },
          config
        )
        .then((response) => {
          response.data.map((element) => {
            this.productos.push({
              nombre: element.nombre,
              id: element.id,
              ramo: element.ramo,
              aseguradoraId: element.aseguradoraId
                ? element.aseguradoraId
                : null,
              aseguradora_id: element.aseguradora_id
                ? element.aseguradora_id
                : null,
              primaNeta: element.primaNeta ? element.primaNeta : null,
              primaTotal: element.total ? element.total : null,
              primerPago: element.primerPago ? element.primerPago : null,
              Subsecuente: element.subsecuentePago
                ? element.subsecuentePago
                : null,
              url_imagen: element.url_imagen ? element.url_imagen : null,
              suma_asegurada: element.suma_asegurada
                ? element.suma_asegurada
                : null,
              etiqueta_aseguradora: element.etiqueta_aseguradora
                ? element.etiqueta_aseguradora
                : null,
            });
          });
          this.copia_productos_originales = this.productos;
          let nombre_aseguradora = this.productoAseguradora.replaceAll(
            " ",
            "_"
          );
          let nombre_producto = this.producto.replaceAll(" ", "_");

          this.img = this.productos[0]
            ? this.productos[0].url_imagen
            : this.img_productos[nombre_aseguradora][nombre_producto];
          this.currentRamo = this.productos[0] ? this.productos[0].ramo : "";
        });
    },
    obtenerRamo(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.form.ramo = productoSelected ? productoSelected.ramo : null;
    },
    obtenerAseguradoraId(id) {
      if (this.productoAseguradora == "Hogar") {
        let productoSelected = this.productos.find(
          (element) => element.id === id
        );
        this.form.suma_asegurada = productoSelected
          ? productoSelected.suma_asegurada
          : 0;
        // productoSelected ? this.form.suma_asegurada = productoSelected.suma_asegurada : this.form.suma_asegurada =  0;
      }
    },
    ObtenerSumaAsegurada(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.form.aseguradoraId = productoSelected
        ? productoSelected.aseguradoraId != null
          ? productoSelected.aseguradoraId
          : productoSelected.aseguradora_id
        : null;
    },
    ObtenerDatosPagos(id) {
      let productoSelected = this.productos.find((element) => element.id == id);
      console.log("aqui est el :", productoSelected);
      if (productoSelected != -1) {
        this.form.primaNeta = productoSelected.primaNeta;
        this.form.primaTotal = productoSelected.primaTotal;
        this.form.montoPrimerPago = productoSelected.primerPago;
        this.form.montoSubsecuente = productoSelected.Subsecuente;
        this.etiqueta_aseguradora = productoSelected.etiqueta_aseguradora;
        console.log("aqui esta la etiqueta: ", this.etiqueta_aseguradora);
      }
    },
    nextForm() {
      let valid = this.$refs.generales.validate();
      if (
        !this.declaracionSaludAccepted &&
        this.productoAseguradora == "Vida-Ahorro"
      ) {
        this.$toast.error(
          "Declaración de salud no aceptada: El cliente no es apto para la emisión del producto Vida + Ahorro"
        );
        return;
      }
      if (valid) {
        this.stepper = 2;
      }
    },
    async LoadDetalleLead() {
      try {
        let { data } = await this.$axios.get(
          `v1/bradescard/uuid/${this.$route.params.uuid}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        this.usuario_detalle = { ...data };
        this.form.tipo_cliente = "titular";
      } catch (exception) {
        console.error(exception);
      }
    },
    actualizarRfc() {
      if (
        !this.form.nombres ||
        !this.form.apellidoPaterno ||
        !this.form.apellidoMaterno ||
        !this.form.comboFechaNacimiento
      )
        return;

      const arregloNacimiento = this.form.comboFechaNacimiento
        .split("-")
        .map((e) => Number(e));
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombres,
        firstLastName: this.form.apellidoPaterno,
        secondLastName: this.form.apellidoMaterno,
        day: arregloNacimiento[2],
        month: arregloNacimiento[1],
        year: arregloNacimiento[0],
      });
      this.form.rfc = rfc.substring(0, rfc.length - 3);
    },

    async crearPoliza() {
      try {
        if (!this.curpFormValid) {
          this.$toast.error("Los campos de entidad y CURP son obligatorios");
          return;
        }
        this.IsIssuing = true;
        let valid = this.$refs.polizas.validate();
        if (!valid) {
          this.IsIssuing = false;
          return;
        }
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        const modificarOperador =
          (this.operadorId && this.form.status < 8) ||
          (this.operadorId && !this.poliza);
        let request = {
          id: this.form.id,
          comercial_asignado: this.comercialAsignado
            ? this.comercialAsignado
            : null,
          forma_pago: this.form.formaPago ? this.form.formaPago : null,
          fecha: this.form.creadoPorDate
            ? moment(this.form.creadoPorDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          poliza: this.form.poliza ? this.form.poliza : null,
          prima_neta: this.form.primaNeta ? this.form.primaNeta : null,
          prima_total: this.form.primaTotal ? this.form.primaTotal : null,
          primer_pago: this.form.montoPrimerPago
            ? this.form.montoPrimerPago
            : null,
          subsecuente_pago: this.form.montoSubsecuente
            ? this.form.montoSubsecuente
            : null,
          pago_id: this.form.periodicidad ? this.form.periodicidad : null,
          aseguradora_id: this.form.aseguradoraId
            ? this.form.aseguradoraId
            : null,
          agente: this.form.agente ? this.form.agente : null,
          estado_id: this.form.estadoRepublicaSelected
            ? this.form.estadoRepublicaSelected
            : null,
          inicio: this.dateFormattedInicioVigencia
            ? moment(this.dateFormattedInicioVigencia, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          anios: this.form.years ? this.form.years : null,
          fin: this.dateFormattedFinVigencia
            ? moment(this.dateFormattedFinVigencia, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          campania: this.form.landing ? this.form.landing : null,
          status_cobro: "",
          operador_id: this.usuario_detalle.operadorId
            ? Number(this.usuario_detalle.operadorId)
            : null,
          origen: "",
          url: "",
          status: this.form.status ? this.form.status : 0,
          telefono: this.form.celular ? this.form.celular : null,
          correo_electronico: this.form.correoElectronico
            ? this.form.correoElectronico
            : null,
          nombre_cliente: `${this.form.nombres} ${this.form.apellidoPaterno} ${this.form.apellidoMaterno}`,
          producto_id: this.form.productos ? this.form.productos : null,
          observaciones: this.form.observaciones
            ? this.form.observaciones
            : null,
          modificado_por: parseInt(this.form.ultimaModoficacion),
          nombre: this.form.nombres ? this.form.nombres : null,
          apellido_paterno: this.form.apellidoPaterno
            ? this.form.apellidoPaterno
            : null,
          apellido_materno: this.form.apellidoMaterno
            ? this.form.apellidoMaterno
            : null,
          codigo_postal: this.form.codigoPostal ? this.form.codigoPostal : null,
          tipo_persona: this.form.regimen ? this.form.regimen : null,
          razon_social: this.form.razonSocial ? this.form.razonSocial : null,
          estado_civil: this.form.statusCivil ? this.form.statusCivil : null,
          direccion: this.form.direccion ? this.form.direccion : null,

          /* nuevos campos */
          notas: this.form.notas ? this.form.notas : null,
          tipo_poliza: this.form.type ? this.form.type : null,
          ejecutivo_de_operaciones: this.form.ejecutivoOperacion
            ? this.form.ejecutivoOperacion
            : null,
          cliente_id: this.form.cliente ? this.form.cliente : null,
          cotizacion: this.form.leadId,
          poliza_anterior: this.form.anterior,
          primer_pago_poliza: this.form.primerPagoCheck,
          fechaPrimerPago: this.dateFormattedPrimerPago
            ? moment(this.dateFormattedPrimerPago, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          checkCancelada: this.form.canceladaCheck
            ? this.form.canceladaCheck
            : false,
          fecha_cancelacion: this.form.canceladaDate
            ? moment(this.form.canceladaDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          motivoCancelacion: this.form.canceladaFinalizadaMotivo
            ? this.form.canceladaFinalizadaMotivo
            : null,
          checkPolizaFinalizada: this.form.finalizadaCheck
            ? this.form.finalizadaCheck
            : false,
          fecha_finalizacion: this.form.finalizadaDate
            ? moment(this.form.finalizadaDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          motivoFinalizacion: this.form.finalizadaMotivo,
          checkPolizaEmitida: this.form.emitidaCheck
            ? this.form.emitidaCheck
            : false,
          //"fecha_emision": this.form.emitidDate ? moment(this.form.emitidDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
          creado_por: parseInt(this.form.creadoPor),
          ultima_modificacion: moment(new Date()).format("YYYY-MM-DD"),
          genero: this.form.selectedGenero,
          fecha_nacimiento: this.dateFormattedFechaNacimiento
            ? moment(this.dateFormattedFechaNacimiento, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
          rfc: this.form.rfc ? this.form.rfc : null,
          checkGenerarCuotas: this.form.checkGenerarCuotas
            ? this.form.checkGenerarCuotas
            : false,
          checkReactivarPoliza: this.form.checkReactivarPoliza
            ? this.form.checkReactivarPoliza
            : false,

          // nuevo campo para el telefono
          globalInteractionId: this.global_id,
          lead_id: this.identificador,
          calle: this.form.calle ? this.form.calle : null,
          num_exterior: this.form.num_exterior ? this.form.num_exterior : null,
          num_interior: this.form.num_interior ? this.form.num_interior : null,
          colonia: this.form.colonia ? this.form.colonia : null,
          municipio: this.form.municipio ? this.form.municipio : null,
          checkgetCertificado: this.form.checkgetCertificado
            ? this.form.checkgetCertificado
            : false,
          tipo_cliente: this.form.tipo_cliente ? this.form.tipo_cliente : null,
          edad: this.form.edad ? this.form.edad : null,
          años_construccion: this.form.años_construccion
            ? this.form.años_construccion
            : null,
          tipo_construccion: this.form.tipo_construccion
            ? this.form.tipo_construccion
            : null,
          num_pisos: this.form.num_pisos ? this.form.num_pisos : null,
          piso_vive: this.form.piso_vive ? this.form.piso_vive : null,
          suma_asegurada: this.form.suma_asegurada
            ? this.form.suma_asegurada
            : null,
          tipo_contratante: this.form.tipo_contratante
            ? this.form.tipo_contratante
            : null,
          ramo: this.form.ramo ? this.form.ramo : null,
          clave_proveedor: "06",
          poliza_aseguradora: this.form.polizaAseguradora
            ? this.form.polizaAseguradora
            : null,
          ...(modificarOperador && { operador_id: this.operadorId }),
          facturar: this.form.facturar || false,
          "regimen-fiscal": this.form["regimen-fiscal"] || null,
          cfdi: this.form.cfdi || null,
        };

        if (this.productoAseguradora == "Celular") {
          request.modeloMarcaCelular = this.form.modeloMarcaCelular;
          request.precioFacturaCelular = this.form.precioFacturaCelular;
        }

        if (this.productoAseguradora == "Vida-Ahorro") {
          request.entidad = this.curpFormData.entidad;
          request.curp = this.curpFormData.curp;
          // Fijo porque sin él no se debería dejar continuar
          request.declaracionSaludAccepted = true;
        }

        this.actualStatus = request.status;

        var route =
          this.poliza != null
            ? "v1/emision/manual/actualizar"
            : "/v1/emision/manual";

        await this.$axios.post(route, request, config).then((response) => {
          var emision = response.data;
          if (route == "/v1/emision/manual") {
            // Se cambio a true para pase a prod
            if (
              this.etiqueta_aseguradora == 1 ||
              this.etiqueta_aseguradora == 2 ||
              this.etiqueta_aseguradora == 3
            ) {
              var routeWsBradescard = "v1/bradescard/cotiza/landing";
              this.$toast.info(
                "Estamos cotizando con la aseguradora espera un poco! ",
                {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                }
              );
              this.cotizarEmision(routeWsBradescard, emision);
            } else if (this.etiqueta_aseguradora == 5) {
              var routeWsAurrera = "v1/aurrera/cotiza/landing";
              this.$toast.info(
                "Estamos cotizando con la aseguradora espera un poco! ",
                {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                }
              );
              this.cotizarEmision(routeWsAurrera, emision);
            } else {
              this.terminarProcesoEmision(emision);
            }
          } else {
            this.terminarProcesoEmision(emision);
          }
        });
      } catch (exception) {
        const opErrorIdentifier = "OperationalException: ";

        if (
          exception.response &&
          exception.response.data.message.includes(opErrorIdentifier)
        ) {
          this.$toast(
            exception.response.data.message.split(opErrorIdentifier)[1]
          );
          this.IsIssuing = false;
        } else console.error(exception);
      }
    },
    terminarProcesoEmision(emision) {
      console.log("Si termino el proceso: ", emision);
      this.IsIssuing = false;
      this.getPolizas(this.identificador);
      this.getNuevasPolizas(this.identificador);
      this.LoadDetalleLead(this.$route.params.uuid);
      // if(this.poliza != null){
      //     // si es actualizacion, se gayurda para el cambio de estado a certificado
      //     this.LoadDetalleLead(this.$route.params.uuid);
      // }
      if (this.actualStatus != emision.status) {
        const configNota = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
            "Content-Type": "multipart/form-data",
          },
        };
        var nombreEstatus =
          emision.status == 0
            ? "Nueva"
            : emision.status == 1
            ? "Emitida_Pendente_de_Pago"
            : emision.status == 2
            ? "Pagada_Parcial"
            : emision.status == 3
            ? "Pagada_Total"
            : emision.status == 4
            ? "Renovada"
            : emision.status == 5
            ? "Finalizada"
            : emision.status == 6
            ? "Cancelada"
            : emision.status == 8
            ? "Emitido_el_certificado"
            : "otra";

        var Mensaje = "Estatus_cambio_a_" + nombreEstatus;
        var formData = new FormData();
        var descripcion = Mensaje;
        var tipoSelected = "Nota";
        var tipoDocumentoSelected = false;
        var nombre = "Cambio_de_estatus_de_poliza";

        formData.append("nota", descripcion ? descripcion : null);
        formData.append("tipo", tipoSelected ? tipoSelected : null);
        formData.append(
          "tipo_documento",
          tipoDocumentoSelected ? tipoDocumentoSelected : null
        );
        formData.append("nombre", nombre ? nombre : null);
        this.$axios
          .post(
            `/v1/emision/documento/upload/${emision.id}`,
            formData,
            configNota
          )
          .then(
            function(result) {
              console.log(result);
            },
            function(error) {
              console.log(error);
            }
          );
      }

      if (this.isEmbbeded == 2) {
        this.$emit("getCurrentEmision", emision);
        this.dialog = false;
        setTimeout(() => {
          this.actualizadaExito = false;
        }, 2000);
      } else {
        this.dialog = false;
        setTimeout(() => {
          this.actualizadaExito = false;
        }, 2000);
      }
    },
    cotizarEmision(route, request) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let jsonResponse = JSON.parse(request.requestJson);
      let arrayNumeroExterior = jsonResponse.num_exterior.split("");
      let arrayNumeroInterior = jsonResponse.num_interior.split("");
      let abecedario = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "ñ",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "w",
        "x",
        "y",
        "z",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let numeroInteriorSingle = "";
      let numeroExteriorSingle = "";

      for (var i = 0; i < arrayNumeroInterior.length - 1; i++) {
        var isLetra = false;
        for (var j = 0; j < abecedario.length - 1; j++) {
          if (arrayNumeroInterior[i] == abecedario[j]) {
            isLetra = true;
          }
        }
        if (!isLetra)
          numeroInteriorSingle =
            numeroInteriorSingle + String(arrayNumeroInterior[i]);
      }
      for (var k = 0; k < arrayNumeroExterior.length - 1; k++) {
        var isLetra2 = false;
        for (var l = 0; l < abecedario.length - 1; l++) {
          if (arrayNumeroExterior[k] == abecedario[l]) {
            isLetra2 = true;
          }
        }
        if (!isLetra2)
          numeroExteriorSingle =
            numeroExteriorSingle + String(arrayNumeroExterior[k]);
      }
      jsonResponse.num_exterior =
        numeroExteriorSingle != "" ? numeroExteriorSingle : "1";
      jsonResponse.num_interior =
        numeroInteriorSingle != "" ? numeroInteriorSingle : "1";
      request.requestJson = JSON.stringify(jsonResponse);

      this.$axios.post(route, request, config).then((response) => {
        if (response.data.status == "FAILED") {
          this.$toast.error(
            "Ocurrio un error al cotizar: " +
              "La colonia que esta tratando de cotizar no esta dentro del rango definido."
          );
          this.terminarProcesoEmision(request);
        } else {
          this.IsIssuing = false;
          if (this.productoAseguradora != "Celular")
            this.actualizarEmisionCotizacion(response.data);
        }
      });
    },
    actualizarEmisionCotizacion(response) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const modificarOperador =
        (this.operadorId && this.form.status < 8) ||
        (this.operadorId && !this.poliza);

      let request = {
        id: response.id,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        forma_pago: this.form.formaPago ? this.form.formaPago : null,
        fecha: this.form.creadoPorDate
          ? moment(this.form.creadoPorDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        prima_neta: this.form.primaNeta ? this.form.primaNeta : null,
        prima_total: this.form.primaTotal ? this.form.primaTotal : null,
        primer_pago: this.form.montoPrimerPago
          ? this.form.montoPrimerPago
          : null,
        subsecuente_pago: this.form.montoSubsecuente
          ? this.form.montoSubsecuente
          : null,
        pago_id: this.form.periodicidad ? this.form.periodicidad : null,
        aseguradora_id: this.form.aseguradoraId
          ? this.form.aseguradoraId
          : null,
        agente: this.form.agente ? this.form.agente : null,
        estado_id: this.form.estadoRepublicaSelected
          ? this.form.estadoRepublicaSelected
          : null,
        inicio: this.dateFormattedInicioVigencia
          ? moment(this.dateFormattedInicioVigencia, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        anios: this.form.years ? this.form.years : null,
        fin: this.dateFormattedFinVigencia
          ? moment(this.dateFormattedFinVigencia, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        campania: this.form.landing ? this.form.landing : null,
        status_cobro: "",
        operador_id: this.usuario_detalle.operadorId
          ? Number(this.usuario_detalle.operadorId)
          : null,
        origen: "",
        url: "",
        status: 8,
        telefono: this.form.celular ? this.form.celular : null,
        correo_electronico: this.form.correoElectronico
          ? this.form.correoElectronico
          : null,
        nombre_cliente: `${this.form.nombres} ${this.form.apellidoPaterno} ${this.form.apellidoMaterno}`,
        producto_id: this.form.productos ? this.form.productos : null,
        observaciones: this.form.observaciones ? this.form.observaciones : null,
        modificado_por: parseInt(this.form.ultimaModoficacion),
        nombre: this.form.nombres ? this.form.nombres : null,
        apellido_paterno: this.form.apellidoPaterno
          ? this.form.apellidoPaterno
          : null,
        apellido_materno: this.form.apellidoMaterno
          ? this.form.apellidoMaterno
          : null,
        codigo_postal: this.form.codigoPostal ? this.form.codigoPostal : null,
        tipo_persona: this.form.regimen ? this.form.regimen : null,
        razon_social: this.form.razonSocial ? this.form.razonSocial : null,
        estado_civil: this.form.statusCivil ? this.form.statusCivil : null,
        direccion: this.form.direccion ? this.form.direccion : null,

        /* nuevos campos */
        notas: this.form.notas ? this.form.notas : null,
        tipo_poliza: this.form.type ? this.form.type : null,
        ejecutivo_de_operaciones: this.form.ejecutivoOperacion
          ? this.form.ejecutivoOperacion
          : null,
        cliente_id: this.form.cliente ? this.form.cliente : null,
        cotizacion: this.form.leadId,
        poliza_anterior: this.form.anterior,
        primer_pago_poliza: this.form.primerPagoCheck,
        fechaPrimerPago: this.dateFormattedPrimerPago
          ? moment(this.dateFormattedPrimerPago, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        checkCancelada: this.form.canceladaCheck
          ? this.form.canceladaCheck
          : false,
        fecha_cancelacion: this.form.canceladaDate
          ? moment(this.form.canceladaDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        motivoCancelacion: this.form.canceladaFinalizadaMotivo
          ? this.form.canceladaFinalizadaMotivo
          : null,
        checkPolizaFinalizada: this.form.finalizadaCheck
          ? this.form.finalizadaCheck
          : false,
        fecha_finalizacion: this.form.finalizadaDate
          ? moment(this.form.finalizadaDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        motivoFinalizacion: this.form.finalizadaMotivo,
        checkPolizaEmitida: this.form.emitidaCheck
          ? this.form.emitidaCheck
          : false,
        //"fecha_emision": this.form.emitidDate ? moment(this.form.emitidDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        creado_por: parseInt(this.form.creadoPor),
        ultima_modificacion: moment(new Date()).format("YYYY-MM-DD"),
        genero: this.form.selectedGenero,
        fecha_nacimiento: this.dateFormattedFechaNacimiento
          ? moment(this.dateFormattedFechaNacimiento, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        rfc: this.form.rfc ? this.form.rfc : null,
        checkGenerarCuotas: this.form.checkGenerarCuotas
          ? this.form.checkGenerarCuotas
          : false,
        checkReactivarPoliza: this.form.checkReactivarPoliza
          ? this.form.checkReactivarPoliza
          : false,

        // nuevo campo para el telefono
        globalInteractionId: this.global_id,
        lead_id: this.identificador,
        calle: this.form.calle ? this.form.calle : null,
        num_exterior: this.form.num_exterior ? this.form.num_exterior : null,
        num_interior: this.form.num_interior ? this.form.num_interior : null,
        colonia: this.form.colonia ? this.form.colonia : null,
        municipio: this.form.municipio ? this.form.municipio : null,
        checkgetCertificado: this.form.checkgetCertificado
          ? this.form.checkgetCertificado
          : false,
        tipo_cliente: this.form.tipo_cliente ? this.form.tipo_cliente : null,
        edad: this.form.edad ? this.form.edad : null,
        años_construccion: this.form.años_construccion
          ? this.form.años_construccion
          : null,
        tipo_construccion: this.form.tipo_construccion
          ? this.form.tipo_construccion
          : null,
        num_pisos: this.form.num_pisos ? this.form.num_pisos : null,
        piso_vive: this.form.piso_vive ? this.form.piso_vive : null,
        suma_asegurada: this.form.suma_asegurada
          ? this.form.suma_asegurada
          : null,
        tipo_contratante: this.form.tipo_contratante
          ? this.form.tipo_contratante
          : null,
        ramo: this.form.ramo ? this.form.ramo : null,
        clave_proveedor: "06",
        poliza_aseguradora: response.uuid_cotizacion,
        poliza: response.uuid_cotizacion,
        ...(modificarOperador && { operador_id: this.operadorId }),
      };

      var route = "v1/emision/manual/actualizar";

      this.$axios.post(route, request, config).then((response) => {
        if (response.data) {
          this.$toast.success("Cotizacion exitosa ");
          this.terminarProcesoEmision(response.data);
        }
      });
    },

    async loadOperadores() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      const { data } = await this.$axios.get("/v1/agentes/operador", config);
      this.operadores = data;
    },

    calcularDireccionAig(cp) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("v1/catalogo/direccion/aig?cp=" + cp, config)
        .then((response) => {
          this.colonias = [];
          response.data.data.colonias.forEach((item) => {
            this.colonias.push(item.nombre);
          });
          this.form.municipio = response.data.data.municipio.nombre;
          this.form.estadoRepublicaSelected = Number(response.data.data.estado.id);
        });
    },
  },
};
</script>
