<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Beneficiario</span>
      </v-card-title>

      <v-card-text v-if="dialog">
        <v-form ref="beneficiario">
          <v-row>
            <v-col cols="4" v-if="form.status == 1">
              <v-checkbox
                label="Aplicar endoso"
                v-model="endosoDetalle.checkEndosoAplicado"
              ></v-checkbox>
            </v-col>

            <v-col cols="4" v-if="endosoDetalle.checkEndosoAplicado">
              <v-text-field
                v-model="endosoDetalle.fechaEndoso"
                label="Fecha de endoso"
                prepend-icon="mdi-calendar"
                v-mask="'##/##/####'"
                placeholder="Requerido"
              ></v-text-field>
            </v-col>
            <v-col cols="4" v-if="endosoDetalle.checkEndosoAplicado">
              <v-select
                label="Motivo de Endoso"
                v-model="endosoDetalle.tipoEndoso"
                :items="[
                  'Estatus Baja',
                  'Corrección RFC',
                  'Corrección Nombre',
                  'Corrección Fecha Nacimiento',
                  'Cambio / corrección teléfono',
                  'Cambio / corrección correo',
                  'Cambio / corrección Parentesco',
                  'Cambio / corrección Porcentaje',
                ]"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="form.status"
                label="Estatus del cliente"
                :items="status"
                item-value="id"
                :disabled="true"
                item-text="name"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Regimen"
                v-model="form.regimen"
                :items="regimenItems"
                :disabled="true"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Nombre(s)"
                v-model="form.nombre"
                :rules="[$rules.required]"
                :disabled="form.status == 1 && isNombreEndoso"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Apellido paterno"
                v-model="form.apellidoPaterno"
                :disabled="form.status == 1 && isNombreEndoso"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Apellido materno"
                v-model="form.apellidoMaterno"
                :disabled="form.status == 1 && isNombreEndoso"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.genero"
                label="Genero"
                :items="generosBeneficiario"
                item-value="id"
                :disabled="form.status == 1"
                item-text="sexo"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Fecha nacimiento"
                v-model="form.fechaNacimiento"
                :rules="[$rules.required]"
                v-mask="'##/##/####'"
                :disabled="form.status == 1 && isFechaNacimientoEndoso"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.estadoCivil"
                label="Estado civil"
                :items="estadosCiviles"
                :disabled="form.status == 1"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="RFC"
                v-model="form.rfc"
                :rules="[$rules.required, $rules.rfc, notEqualRfcRule]"
                :disabled="form.status == 1 && isRfcEndoso"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="form.correo"
                label="Correo Electrónico"
                :items="prediccionesCorreo"
                :filter="filtroCorreo"
                :disabled="form.status == 1 && isCorreoEndoso"
                :rules="[$rules.required, $rules.email, validarDominioComun]"
                :append-icon="null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Teléfono"
                v-model="form.telefono"
                v-mask="'##########'"
                :rules="[$rules.required]"
                :disabled="form.status == 1 && isTelefonoEndoso"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Parentesco"
                :items="parentesco"
                item-text="descripcion"
                item-value="id"
                v-model="form.parentesco"
                :rules="[$rules.required]"
                :disabled="form.status == 1 && isParentescoEndoso"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="porcentaje"
                v-model="form.porcentaje"
                :rules="[
                  $rules.required,
                  $rules.menorIgual(diferencia_porcentaje),
                  $rules.notCero(),
                ]"
                :disabled="form.status == 1 && isPorcentjeEndoso"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Creado por"
                v-model="form.creadoPor"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Fecha de creación"
                v-model="form.fechaCreacion"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Modificado por"
                v-model="form.modificadoPor"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Ultima modificación"
                v-model="form.ultimaModificacion"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        <v-btn v-if="uuid == null" color="primary" text @click="store"
          >Guardar</v-btn
        >
        <v-btn v-else small color="primary" @click="update">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfcFacil from "rfc-facil";
import { mapActions } from "vuex";
import { ListExpanded } from "~/plugins/mixins.js";

import {
  dominiosComunes,
  validarDominioComun,
} from "@/views/pages/bradescard/components/documentos/ValidacionDominiosComunes";
var moment = require("moment-timezone");

export default {
  name: "beneficiarios.dialog",
  mixins: [ListExpanded],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: null,
    },
    porcentajeTotal: {
      type: Number,
      default: 100,
    },
    emision: {
      type: Number,
      require: true,
    },
    rfcPoliza: {
      type: String,
    },
    emisionStatus: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      dialog: false,
      diferencia_porcentaje: 100,
      form: {
        id: null,
        uuid: null,
        emisionId: null,
        status: 0,
        regimen: "Física",
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        rfc: null,
        correo: null,
        telefono: null,
        parentesco: null,
        porcentaje: null,
        razonSocial: null,

        creadoPor: null,
        fechaCreacion: null,
        modificadoPor: null,
        ultimaModificacion: null,
      },
      busquedaCorreo: "",
      cargarRFC: true,
      endosoDetalle: {
        fechaEndoso: null,
        tipoEndoso: null,
        checkEndosoAplicado: null,
      },
      endosoJson: null,
      bajaBeneficirio: false,
      backpack: {
        id: null,
        uuid: null,
        emisionId: null,
        status: 0,
        regimen: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        rfc: null,
        correo: null,
        telefono: null,
        parentesco: null,
        porcentaje: null,
        razonSocial: null,
        creadoPor: null,
        fechaCreacion: null,
        modificadoPor: null,
        ultimaModificacion: null,
      },
      numberRule: [
        (v) => v.length > 0 || "campo requerido",
        (v) => v > 0 || "El valor debe ser mayor a cero",
      ],
    };
  },

  computed: {
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },

    fechaNacimientoRequest() {
      if (!this.form.fechaNacimiento) return null;
      const fechaArray = this.form.fechaNacimiento.split("/");
      if (fechaArray.length < 3) return null;
      return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
    },
    isRfcEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Corrección RFC"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isNombreEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Corrección Nombre"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isFechaNacimientoEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Corrección Fecha Nacimiento"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isTelefonoEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Cambio / corrección teléfono"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isCorreoEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Cambio / corrección correo"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isParentescoEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Cambio / corrección Parentesco"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isPorcentjeEndoso() {
      if (
        this.endosoDetalle.checkEndosoAplicado &&
        this.endosoDetalle.tipoEndoso == "Cambio / corrección Porcentaje"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  async mounted() {
    console.log("porcentaje total madafaka: ", this.porcentajeTotal);
    this.dialog = this.open;
    if (this.uuid != null) {
      // en caso de que sea una actualizacion
      await this.getBeneficiario();
    } else {
      // en caso de que no, se toma la suma total
      this.diferencia_porcentaje = 100 - this.porcentajeTotal;
    }
  },
  watch: {
    dialog: function(val) {
      this.$emit("update:open", val);
    },

    "form.nombre"() {
      this.actualizarRfc();
    },

    "form.apellidoPaterno"() {
      this.actualizarRfc();
    },

    "form.apellidoMaterno"() {
      this.actualizarRfc();
    },

    "form.fechaNacimiento"() {
      this.actualizarRfc();
    },
    "endosoDetalle.checkEndosoAplicado"(newValue) {
      if (newValue) {
        this.endosoDetalle.fechaEndoso = moment().format("DD/MM/YYYY");
      } else {
        this.restoreEndosoData();
      }
    },
  },
  methods: {
    ...mapActions("polizas", ["getBeneficiarios"]),

    validarDominioComun,
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },

    notEqualRfcRule(v) {
      return (
        v !== this.rfcPoliza ||
        "El RFC no puede ser igual al del titular o asegurado"
      );
    },

    actualizarRfc() {
      if (!this.cargarRFC) return;
      if (
        !this.form.nombre ||
        !this.form.apellidoPaterno ||
        !this.form.apellidoMaterno ||
        !this.form.fechaNacimiento ||
        this.form.fechaNacimiento.length < 10
      )
        return;

      const arregloNacimiento = this.fechaNacimientoRequest
        .split("-")
        .map((e) => Number(e));
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombre,
        firstLastName: this.form.apellidoPaterno,
        secondLastName: this.form.apellidoMaterno,
        day: arregloNacimiento[2],
        month: arregloNacimiento[1],
        year: arregloNacimiento[0],
      });
      this.form.rfc = rfc.substring(0, rfc.length - 3);
    },
    async getBeneficiario() {
      this.cargarRFC = false;
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        this.$axios
          .get(`v1/beneficiario/uuid/${this.uuid}`, config)
          .then((data) => {
            this.form.agenteId = data.data.agenteId;
            this.form.apellidoMaterno = data.data.apellidoMaterno;
            this.form.apellidoPaterno = data.data.apellidoPaterno;
            this.form.correo = data.data.correo;
            this.form.creadoPor = data.data.creadoPor;
            this.form.edad = data.data.edad;
            this.form.emisionId = data.data.emisionId;
            this.form.errorMessage = data.data.errorMessage;
            this.form.estadoCivil = data.data.estadoCivil;
            this.form.fechaCreacion = data.data.fechaCreacion;
            this.form.fechaNacimiento = data.data.fechaNacimiento;
            this.form.genero = data.data.genero;
            this.form.id = data.data.id;
            this.form.modificadoPor = data.data.modificadoPor;
            this.form.nombre = data.data.nombre;
            this.form.parentesco = data.data.parentesco;
            this.form.porcentaje = data.data.porcentaje;
            this.form.razonSocial = data.data.razonSocial;
            this.form.rfc = data.data.rfc;
            this.form.status = data.data.status;
            this.form.telefono = data.data.telefono;
            this.form.ultimaModificacion = data.data.ultimaModificacion;
            this.form.uuid = data.data.uuid;
            //Campos para backpack de endoso
            this.backpack.agenteId = data.data.agenteId;
            this.backpack.apellidoMaterno = data.data.apellidoMaterno;
            this.backpack.apellidoPaterno = data.data.apellidoPaterno;
            this.backpack.correo = data.data.correo;
            this.backpack.creadoPor = data.data.creadoPor;
            this.backpack.edad = data.data.edad;
            this.backpack.emisionId = data.data.emisionId;
            this.backpack.errorMessage = data.data.errorMessage;
            this.backpack.estadoCivil = data.data.estadoCivil;
            this.backpack.fechaCreacion = data.data.fechaCreacion;
            this.backpack.genero = data.data.genero;
            this.backpack.id = data.data.id;
            this.backpack.modificadoPor = data.data.modificadoPor;
            this.backpack.nombre = data.data.nombre;
            this.backpack.parentesco = data.data.parentesco;
            this.backpack.porcentaje = data.data.porcentaje;
            this.backpack.razonSocial = data.data.razonSocial;
            this.backpack.rfc = data.data.rfc;
            this.backpack.status = data.data.status;
            this.backpack.telefono = data.data.telefono;
            this.backpack.ultimaModificacion = data.data.ultimaModificacion;
            this.backpack.uuid = data.data.uuid;
            this.busquedaCorreo = this.form.correo;
            const fNacimientoArray = this.form.fechaNacimiento.split("-");
            this.form.fechaNacimiento = `${fNacimientoArray[2]}/${fNacimientoArray[1]}/${fNacimientoArray[0]}`;
            this.backpack.fechaNacimiento = `${fNacimientoArray[2]}/${fNacimientoArray[1]}/${fNacimientoArray[0]}`;
            this.diferencia_porcentaje =
              100 - (this.porcentajeTotal - parseFloat(data.porcentaje));
          });
      } catch (exception) {
        console.error(exception);
      }

      setTimeout(() => {
        this.cargarRFC = true;
      }, 100);
    },
    async store() {
      try {
        // beneficiario
        let valid = this.$refs.beneficiario.validate();
        if (!valid) {
          return;
        }

        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let formdata = {
          emisionId: this.emision,
          status: this.form.status,
          regimen: this.form.regimen,
          nombre: this.form.nombre,
          apellidoPaterno: this.form.apellidoPaterno,
          apellidoMaterno: this.form.apellidoMaterno,
          genero: this.form.genero,
          fechaNacimiento: this.fechaNacimientoRequest,
          estadoCivil: this.form.estadoCivil,
          rfc: this.form.rfc,
          correo: this.form.correo,
          telefono: this.form.telefono,
          parentesco: this.form.parentesco,
          porcentaje: parseFloat(this.form.porcentaje),
          razonSocial: this.form.razonSocial,
          creadoPor: this.form.creadoPor,
          fechaCreacion: this.form.fechaCreacion,
          modificadoPor: this.form.modificadoPor,
          ultimaModificacion: this.form.ultimaModificacion,
        };

        if (
          this.emisionStatus == "Emitida Pendente de Pago" ||
          this.emisionStatus == "Pagada Parcial" ||
          this.emisionStatus == "Pagada Total"
        ) {
          formdata.status = 1;
        }
        try {
          await this.$axios.post(`v1/beneficiario/insert`, formdata, config);
          await this.getBeneficiarios(formdata.emisionId);
          this.dialog = false;
        } catch (error) {
          const operationalErrorType =
            "com.segurify.util.OperationalException: ";
          if (
            error.response &&
            error.response.data.message.includes(operationalErrorType)
          ) {
            const errorMessage = error.response.data.message.split(
              operationalErrorType
            )[1];
            this.$toast.error(errorMessage);
          }
        }
      } catch (exception) {
        console.error(exception);
      }
    },
    contruirEndoso() {
      var endosoJson = {
        fechaEndoso: null,
        tipoEndoso: null,
        detalleEndoso: "",
        usuario: localStorage.agenteName,
      };
      if (
        this.endosoDetalle.tipoEndoso == "Corrección RFC" ||
        this.endosoDetalle.tipoEndoso == "Corrección Nombre" ||
        this.endosoDetalle.tipoEndoso == "Corrección Fecha Nacimiento" ||
        this.endosoDetalle.tipoEndoso == "Cambio / corrección teléfono" ||
        this.endosoDetalle.tipoEndoso == "Cambio / corrección Parentesco"
      ) {
        endosoJson.tipoEndoso = "Corrección datos del Beneficiario";
      }
      if (this.endosoDetalle.tipoEndoso == "Cambio / corrección Porcentaje") {
        endosoJson.tipoEndoso = "Actualización de % del Beneficiario​";
      }
      if (this.endosoDetalle.tipoEndoso == "Estatus Baja") {
        endosoJson.tipoEndoso = "Baja de Beneficiario";
        endosoJson.detalleEndoso = "Baja de Beneficiario";
        this.bajaBeneficirio = true;
      }
      endosoJson.fechaEndoso = this.endosoDetalle.fechaEndoso;

      if (this.form.nombre != this.backpack.nombre) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el nombre de " +
          this.backpack.nombre +
          " a " +
          this.form.nombre +
          " ,";
      }
      if (this.form.apellidoPaterno != this.backpack.apellidoPaterno) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el apellido parterno de " +
          this.backpack.apellidoPaterno +
          " a " +
          this.form.apellidoPaterno +
          " ,";
      }
      if (this.form.apellidoMaterno != this.backpack.apellidoMaterno) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el apellido materno de " +
          this.backpack.apellidoMaterno +
          " a " +
          this.form.apellidoMaterno +
          " ,";
      }
      if (this.form.fechaNacimiento != this.backpack.fechaNacimiento) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio la fecha de nacimiento de " +
          this.backpack.fechaNacimiento +
          " a " +
          this.form.fechaNacimiento +
          " ,";
      }
      if (this.form.rfc != this.backpack.rfc) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el RFC de " +
          this.backpack.rfc +
          " a " +
          this.form.rfc +
          " ,";
      }
      if (this.form.selectedGenero != this.backpack.selectedGenero) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el genero de " +
          this.backpack.selectedGenero +
          " a " +
          this.form.selectedGenero +
          " ,";
      }
      if (this.form.telefono != this.backpack.telefono) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el telefono de " +
          this.backpack.telefono +
          " a " +
          this.form.telefono +
          " ,";
      }
      if (this.form.correo != this.backpack.correo) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el correo de " +
          this.backpack.correo +
          " a " +
          this.form.correo +
          " ,";
      }
      if (this.form.porcentaje != this.backpack.porcentaje) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el numero exterior de " +
          this.backpack.porcentaje +
          " a " +
          this.form.porcentaje +
          " ,";
      }
      if (this.form.parentesco != this.backpack.parentesco) {
        endosoJson.detalleEndoso =
          endosoJson.detalleEndoso +
          " Se cambio el numero interior de " +
          this.backpack.parentesco +
          " a " +
          this.poliza.parentesco +
          " ,";
      }

      return JSON.stringify(endosoJson);
    },
    async update() {
      try {
        if (this.endosoDetalle.checkEndosoAplicado) {
          this.endosoJson = this.contruirEndoso();
        }
        // let valid = this.$refs.beneficiario.validate();
        // if (!valid) {
        //     return;
        // }
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let formdata = {
          id: this.form.id,
          uuid: this.form.uuid,
          emisionId: this.form.emisionId,
          status: this.bajaBeneficirio ? -1 : this.form.status,
          regimen: this.form.regimen,
          nombre: this.form.nombre,
          apellidoPaterno: this.form.apellidoPaterno,
          apellidoMaterno: this.form.apellidoMaterno,
          genero: this.form.genero,
          fechaNacimiento: this.fechaNacimientoRequest,
          estadoCivil: this.form.estadoCivil,
          rfc: this.form.rfc,
          correo: this.form.correo,
          telefono: this.form.telefono,
          parentesco: this.form.parentesco,
          porcentaje: this.bajaBeneficirio
            ? 0
            : parseFloat(this.form.porcentaje),
          razonSocial: this.form.razonSocial,

          creadoPor: this.form.creadoPor,
          fechaCreacion: this.form.fechaCreacion,
          modificadoPor: this.form.modificadoPor,
          ultimaModificacion: this.form.ultimaModificacion,
          endoso: this.endosoJson != null ? this.endosoJson : null,
        };
        await this.$axios.put(
          `v1/beneficiario/update/${this.uuid}`,
          formdata,
          config
        );
        await this.getBeneficiarios(formdata.emisionId);

        this.dialog = false;
      } catch (exception) {
        console.error(exception);
      }
    },
  },
};
</script>
