<template>
  <div>
    <v-card color="grey lighten-3" tile elevation="0">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-subheader class="ma-0 pa-0 text-title">
              Declaración de salud
            </v-subheader>
          </v-col>
          <v-col cols="12">
            <p>
              Declaro que estoy actualmente en buen estado de salud y que
              durante los últimos 3 años no he sufrido, ni padezco en el
              presente, ninguna de las enfermedades (afectaciones) siguientes:
              Ataque al corazón, apoplejía, cáncer, enfermedad del riñón,
              hipertensión arterial, desorden de pulmones o sistema
              respiratorio, desorden del sistema muscular - esquelético o
              desorden del sistema nervioso.
            </p>
            <p>
              Tampoco soy portador del Virus de Inmunodeficiencia Humana (VIH) o
              padezco de Síndrome de Inmunodeficiencia Adquirida (SIDA) y
              declaro que no consumo drogas o estupefacientes, ni soy alcohólico
              y que al momento de firmar esta declaración no tengo ninguna
              cirugía pendiente de realizarme o se me haya indicado que tengo
              una enfermedad terminal.
            </p>
            <p>
              Declaro de manera libre y espontanea, bajo protesta de decir la
              verdad, que la información proporcionada es verdadera, completa y
              correcta y me comprometo a actualizar de algún cambio.
            </p>
          </v-col>
          <v-col cols="12" class="pl-4">
            <v-radio-group v-model="declaracionAccepted" :column="false">
              <v-radio label="Sí" :value="true"></v-radio>
              <v-radio label="No" :value="false" class="ml-3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    accepted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      declaracionAccepted: this.accepted,
    };
  },

  watch: {
    declaracionAccepted(v) {
      this.$emit("change", v);
    },
  },
};
</script>
