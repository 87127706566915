<template>
  <v-card color="#f3f3f3" elevation="0">
    <v-card-text>
      <v-form v-model="isformValid">
        <v-row dense>
          <v-col cols="12">
            <v-alert type="info" text dense
              >Campos requeridos para producto Vida-Ahorro</v-alert
            >
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              v-model="form.entidad"
              dense
              label="Entidad de Nacimiento"
              placeholder="Requerido"
              :disabled="canEdit || lockEntidadInput"
              :rules="[$rules.required]"
              :items="estados"
              item-text="nombre"
              item-value="entidad"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="form.curp"
              dense
              label="CURP"
              placeholder="Requerido"
              :disabled="canEdit || !canEditCurp"
              :rules="[$rules.required, $rules.curp]"
              :hint="curpHint"
              :persistent-hint="!canEditCurp"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mainAxios } from "../../../../../mainAxios";
export default {
  props: {
    formData: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingCurp: false,
      isformValid: false,
      attempts: 0,
      form: {
        entidad: "",
        curp: "",
      },
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      estados: [],
      canEditCurp: false,
      diccionarioSexo: {
        Masculino: "H",
        Femenino: "M",
      },
      toastConfig: {
        position: "top-right",
        timeout: 10000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      },
    };
  },

  computed: {
    lockEntidadInput() {
      return !!this.form.curp && this.attempts == 1;
    },

    curpHint() {
      return this.canEditCurp ? "" : "Se calculará automáticamente";
    },

    curpBody() {
      return {
        nombre: this.formData.nombres
          ? this.trimString(this.formData.nombres)
          : "",
        primerApellido: this.formData.apellidoPaterno
          ? this.trimString(this.formData.apellidoPaterno)
          : "",
        segundoApellido: this.formData.apellidoMaterno
          ? this.trimString(this.formData.apellidoMaterno)
          : "",
        fechaNacimiento: this.formData.fechaNacimiento || "",
        entidad: this.form.entidad,
        sexo: this.formData.selectedGenero
          ? this.diccionarioSexo[this.formData.selectedGenero]
          : "",
      };
    },
  },

  watch: {
    curpBody(v) {
      const keys = Object.keys(v);
      if (keys.every((key) => !!v[key])) this.getCurp();
    },

    isformValid(v) {
      this.$emit("change", v);
    },

    "form.entidad"() {
      this.$emit("formChange", this.form);
    },

    "form.curp"() {
      this.$emit("formChange", this.form);
    },
  },

  mounted() {
    this.loadEstados();
    this.setCurrentFormData();
  },

  methods: {
    setCurrentFormData() {
      const { entidad, curp } = this.formData;
      if (entidad) this.form.entidad = entidad;
      if (curp) this.form.curp = curp;
      if (curp) this.attempts = 1;
    },

    loadEstados() {
      mainAxios.get("/v1/estados/list", this.config).then((response) => {
        this.estados = response.data.data.estados;
      });
    },

    trimString(input) {
      return input
        .toUpperCase() // Convert to uppercase
        .normalize("NFD") // Normalize the string to decompose accents
        .replace(/[\u0300-\u036f]/g, ""); // Remove the decomposed accent marks
    },

    async getCurp() {
      if (this.attempts > 0) return;
      this.loadingCurp = true;
      this.attempts = this.attempts + 1;

      this.$toast.info("Calculando CURP, espera un momento", this.toastConfig);

      try {
        const response = await mainAxios.post(
          "/v1/renapo/curp",
          this.curpBody,
          { ...this.config, timeout: 10000 }
        );
        this.form.curp = response.data.data.curp;
        this.$toast.success("CURP Calculada correctamente", this.toastConfig);
      } catch (error) {
        this.handleCurpError();
      } finally {
        this.loadingCurp = false;
      }
    },

    handleCurpError() {
      this.$toast.error(
        "Error calculando CURP, el campo se ha habilitado para su llenado manual",
        this.toastConfig
      );
      this.canEditCurp = true;
    },
  },
};
</script>
